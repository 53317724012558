.header {
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px;
  gap: 32px;

  background: var(--color-bg-white, #FFF);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.20);

  &__right-content {
    display: flex;
    gap: 32px;
    width: 100%;
  }

  &__nav-left {
    display: flex;
  }
}
