@mixin family-sans($color, $size, $weight, $lh) {
  color: $color;
  font-family: Public Sans;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $lh;
}

@mixin family-roboto($color, $size, $weight, $lh) {
  color: $color;
  font-family: Roboto;
  font-size: $size;
  font-style: normal;
  font-weight: $weight;
  line-height: $lh;
}
