@import '../styles/mixins';

.navigation {
  max-width: 724px;
  width: 100%;
  align-self: center;

  &__list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style: none;
  }

  &__link {
    @include family-sans(var(--color-text-dark), 14px, 400, 14px);
    text-decoration: none;

    &:hover {
      color: var(--color-text-blue);
    }
  }

  @media screen and (max-width: 852px) {
    display: none;
  }
}

.nav-left {
  &__list {
    display: flex;
    gap: 32px;
    list-style: none;

    @media screen and (max-width: 1162px) {
      display: none;
    }
  }

  &__item {
    position: relative;

    &::after{
      content: '';
      position: absolute;
      right: -16px;
      top: 50%;
      display: block;
      width: 3px;
      height: 3px;
      background-color: var(--color-text-dark);
      border-radius: 50%;
    }

    &:last-child {
      &::after{
        content: '';
        display: none;
      }
    }
  }

  &__link {
    @include family-sans(var(--color-text-dark), 14px, 400, 14px);
    text-decoration: none;

    &:hover {
      color: var(--color-text-blue);
    }
  }
}
