.first-block__big-slider-wrap .swiper {
  max-width: 700px;
  position: relative;

  @media screen and (max-width: 768px) {
    max-width: 500px;
  }

  @media screen and (max-width: 545px) {
    max-width: 300px;
  }
}

.slider-arrow--next {
  position: absolute;
  right: -10px;
  z-index: 10;
  top: calc(50% - 17px);
  border: none;
  background: transparent;
  cursor: pointer;
}

.slider-arrow--prev {
  position: absolute;
  left: -10px;
  z-index: 10;
  top: calc(50% - 17px);
  border: none;
  background: transparent;
  cursor: pointer;
}

.small-slider-wrap .swiper {
  padding: 0 5px 0 10px;
}
