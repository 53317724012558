@import '../styles/mixins';

#nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
  width: 25px;
  height: 15px;
  position: relative;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 9px;
  width: 100%;
  background: #1e36a7;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 5px;
}

#nav-icon1 span:nth-child(3) {
  top: 10px;
}

#nav-icon1.open span:nth-child(1) {
  top: 5px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 5px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

/* Icon 2 */

#nav-icon2 {
  // display: flex;
  flex-direction: column;
  justify-items: center;
}

#nav-icon2 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 50%;
  background: #1e36a7;
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon2 span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

#nav-icon2 span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

#nav-icon2 span:nth-child(1), #nav-icon2 span:nth-child(2) {
  top: 0px;
}

#nav-icon2 span:nth-child(3), #nav-icon2 span:nth-child(4) {
  top: 5px;
}

#nav-icon2 span:nth-child(5), #nav-icon2 span:nth-child(6) {
  top: 10px;
}

#nav-icon2.open span:nth-child(1),#nav-icon2.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon2.open span:nth-child(2),#nav-icon2.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon2.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

#nav-icon2.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

#nav-icon2.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

#nav-icon2.open span:nth-child(5) {
  left: 5px;
  top: 10px;
}

#nav-icon2.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 10px;
}

.menu {
  position: fixed;
  top: 0;
  z-index: 2;
  display: grid;
  grid-template-columns: 200px;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background: var(--color-bg-white, #FFF);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.04), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.20);
  transform: translateY(-100%);
  transition: transform 0.6s;
  padding: 60px 10px 20px;
  list-style: none;

  @media screen and (max-width: 852px) {
    grid-template-columns: 1fr 1fr;
    // min-width: 748px;
  }
}

.show {
  transform: translateY(0);
}

.burger-btn {
  display: none;

  @media screen and (max-width: 1162px) {
    display: flex;
  }
}

.menu-link {
  @include family-sans(var(--color-text-dark), 14px, 400, 14px);
  text-decoration: none;

  &:hover {
    color: var(--color-text-blue);
  }
}

.visible {
  display: none;

  @media screen and (max-width: 852px) {
    display: flex;
  }
}

.flex-end {
  @media screen and (max-width: 852px) {
    justify-self: flex-end;
  }
}