.bg-first-blok {
  width: 100%;
  background: url('../images/BG.png');
  background-position: center;
  background-size: cover;
}

.bg-third-blok {
  width: 100%;
  background: url('../images/BG-third.png');
  background-position: center;
  background-size: cover;
}

.wrapper {
  // min-width: 748px;
}

.content {
  margin: 0 auto;
  max-width: 1277px;
  padding: 0 15px;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 1099px) {
    align-items: center;
  }
}

.text {
  @include family-sans(var(--color-text-dark), 44px, 700, 53px);
  margin-bottom: 16px;

  @media screen and (max-width: 1099px) {
    text-align: center;
    margin-top: 45px;
  }
}

.subtext {
  @include family-sans(var(--color-text-dark), 22px, 400, 31px);
  color: var(--color-text-dark, #070708);
}

.first-block {
  padding-top: 93px;

  &__big-slider-wrap {
    display: flex;
    gap: 49px;
    margin-bottom: 32px;

    @media screen and (max-width: 1099px) {
      flex-direction: column-reverse;
    }
  }

  &__btn {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 239px;
    height: 42px;
    cursor: pointer;
    border-radius: 8px;
    border: 0.5px solid var(--color-button-dark-grey, #CFCFD2);
    background: var(--color-button-white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(6, 25, 56, 0.05);
    text-decoration: none;

    @include family-roboto(var(--color-text-blue), 16px, 500, 18px);

    @media screen and (max-width: 1099px) {
      margin-top: 44px;
    }
  }

  @media screen and (max-width: 1099px) {
    padding-top: 23px;
  }
}

.small-slider-wrap {
  max-width: 1305px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.big-title {
  @include family-sans(var(--color-text-gray), 16px, 400, 16px);
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 67px;
  padding-top: 5px;
}

.img-response {
  object-fit: cover;
  width: 100%;
}

.about-company {
  display: flex;
  gap: 10px;
  justify-content: space-between;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 488px;
  }

  &__subtext {
    @include family-sans(var(--color-text-dark), 22px, 400, 31px);

    span {
      @include family-sans(var(--color-text-dark), 22px, 700, 31px);
    }
  }
  
  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 32px;
    padding-left: 22px
  }

  &__item {
    @include family-sans(var(--color-blue-700), 22px, 400, 31px);
    list-style-position: outside;
    
    a {
      text-decoration: none;
      color: var(--color-blue-700);
    }
  }

  img {
    object-fit: contain;
    width: 100%;
  }

  @media screen and (max-width: 1099px) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.reverce {
  @media screen and (max-width: 1099px) {
    flex-direction: column;
  }
}

.faq-list {
  max-width: 990px;
  margin: 0 auto;
}

.title {
  color: var(--text-main-black, #070708);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px; /* 116.667% */
  margin-bottom: 16px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    line-height: 35px; /* 116.667% */
  }

  @media screen and (max-width: 390px) {
    font-size: 27px;
    line-height: 31px; /* 116.667% */
  }
}

.subtitle {
  color: var(--text-main-black, #070708);
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  margin-bottom: 16px;
}

.wrap {
  padding: 15px;
}