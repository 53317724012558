.footer {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 32px;
    // max-width: 1200px;
    margin: 0 auto;
    background-color: var(--ui-white-color);

    @media screen and (max-width: 818px) {
      flex-direction: column;
      gap: 10px;
      padding: 18px 0 0;
    }
  }

  &__policy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // max-width: 345px;
    // width: 100%;
    text-decoration: none;
    gap: 10px;
    list-style: none;

    &:nth-child(4) {
      .footer__text {
        div {
          border-right: none;
        }
      }
    }
  
    @media screen and (max-width: 454px) {
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }

  &__text {
    color: var(--color-text-dark, #070708);
    // text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    background-color: transparent;

    div {
      text-decoration: none;
      background-color: transparent;
      color: #424245;
      border-right: 1px solid #424245;
      padding-right: 12px;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (max-width: 565px) {
      margin-bottom: 18px;
    }
  }

  &__copy {
    color: var(--color-text-gray, #929298);
    text-align: center;
    font-family: Public Sans;
    font-size: 14px;
    line-height: 1.33337;
    font-weight: 400;
    letter-spacing: -.01em;
    margin-right: 20px;

    @media screen and (max-width: 818px) {
      margin-right: 0;
    }
  }
}
