.terms {
  max-width: 1137px;
  margin: 0 auto;

  &__subtitle {
    color: var(--text-secondary-light, #74747B);
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 21.6px */

    a {
      background: transparent;
      text-decoration: underline;
    }

    
    @media screen and (max-width: 768px) {
      font-size: 16px !important;
    }
  }

  &__title-h3 {
    color: var(--text-main-black, #070708);
    font-feature-settings: 'clig' off, 'liga' off;

    /* H5 */
    font-family: Roboto;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 100% */
    letter-spacing: 0.18px;

    @media screen and (max-width: 768px) {
      font-size: 22px !important;
    }
  }
}

.padding-top {
  padding-top: 40px;
}

.padding-bottom {
  padding-bottom: 24px;
}

.text-upper {
  text-transform: uppercase;
}